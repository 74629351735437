import React, { Component } from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import axios from 'axios';
import ReactGA from 'react-ga'

import config from '../../config';
import styles from './signup.module.scss'

import funnel from '../../assets/icons/signup.svg'
import arrow from '../../assets/icons/side.svg'
import skill from '../../assets/icons/skillIndia.svg'
import NSDC from '../../assets/icons/nsdclogo1.svg'
import SMIS from '../../assets/icons/smis1.svg'
import better from '../../assets/icons/betterlogo.svg'

const options = [
    { value: 'ministry', label: 'Ministry of Skill Developement and Entrepreneurship' },
    { value: 'other', label: 'Other Ministries' },
    { value: 'government', label: 'Government Agencies'},
    { value: 'national', label: 'National Skill Development Corporation'},
    { value: 'sector', label: 'Sector Skill Council'},
    { value: 'training', label: 'Training Partners'}
];

const options2 = [
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Apparels', label: 'Apparel'},
    { value: 'Aviation & AeroSpace', label: 'Aerospace and Aviation'},
    { value: 'Beauty & Wellness', label: 'Beauty and Wellness'},
    { value: 'Capital Goods', label: 'Capital Goods'},
    { value: 'BFSI', label: 'BFSI'},
    { value: 'Coating & Painting', label: 'Paints and Coatings'},
    { value: 'Construction', label: 'Construction'},
    { value: 'Domestic Workers', label: 'Domestic Workers'},
    { value: 'Electronics & Hardware', label: 'Electronics and Hardware'},
    { value: 'Food Processing', label: 'Food Processing'},
    { value: 'Furniture & Fittings', label: 'Furniture and Fittings'},
    { value: 'Gems & Jewellery', label: 'Gem and Jewellery'},
    { value: 'Green Jobs', label: 'Green Jobs'},
    { value: 'Handicrafts', label: 'Handicrafts and Carpet'},
    { value: 'Healthcare', label: 'Healthcare'},
    { value: 'Infrastructure Equipment', label: 'Infrastructure Equipment'},
    { value: 'Instrumentation', label: 'Instrumentation Automation Surveillance ....'},
    { value: 'Iron & Steel', label: 'Iron and Steel'},
    { value: 'IT-ITeS', label: 'IT-ITeS'},
    { value: 'Leather', label: 'Leather'},
    { value: 'Life Sciences', label: 'Life Sciences'},
    { value: 'Logistics', label: 'Logistics'},
    { value: 'Management', label: 'Management'}, 
    { value: 'Media', label: 'Media and Entertainment'},
    { value: 'Mining', label: 'Mining'},
    { value: 'Oil & Gas', label: 'Hydrocarbon'},
    { value: 'Plumbing', label: 'Plumbing'},
    { value: 'Power', label: 'Power'},
    { value: 'PwD', label: 'Persons with Disability'},
    { value: 'Retail', label: 'Retail'},
    { value: 'Rubber', label: 'Rubber'},
    { value: 'Sports', label: 'Sports'},
    { value: 'Telecom', label: 'Telecom'},
    { value: 'Textiles & Handlooms', label: 'Textile and Handlooms'},
    { value: 'Tourism & Hospitality', label: 'Tourism and Hospitality'},
    { value: 'Strategic Manufacturing', label: 'Strategic Manufacturing'},
    { value: 'Chemical & PetroChemical', label: 'Chemical and PetroChemical'}
];



const colourStyles = {
    control: (base, state) => {
        return {
            boxSizing: "border-box",
            height: '3.3333vw',
            width: '31.6666vw',
            border: '1px solid #E5E5E5',
            borderRadius: 8,
            backgroundColor: '#FFFFFF'
        }
    },
    valueContainer: () => {
        return {
            height: '1.041666vw',
            width: '25.76388888vw',
            color: '#939393',
            fontFamily: 'Gilroy',
            fontSize: '1.1111112vw',
            letterSpacing: 0,
            lineHeight: '1.04166vw',
            marginTop: '1.21527777vw',
            marginLeft: '1.1458333333vw'
        }
    },
    singleValue: () => {
        return {
            height: '1.04166vw',
            width: '27.25vw',
            color: '#3C3C3B',
            fontFamily: 'Gilroy-Bold',
            fontSize: '1.11112vw',
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '1.0416666vw'
        }
    },
    indicatorsContainer: () => {
        return {
            position: 'absolute',
            top: '0.277777vw',
            left: '28.33333vw'
        }
    },
    indicatorSeparator: () => null,
    dropdownIndicator: (base, state) => {
        return {
            ...base,
            padding: '10px',
            fontSize: '12px'
        }
    },

    input: () => {
        return {
            height: 0,
            width: 0,
            outline: 'none',
            display: 'inline-block',
            cursor: 'pointer',
            marginTop: '-5.04166vw'
        }
    },
    placeholder: () => {
        return {
            height: '1.11112vw',
            width: '10.48611vw',
            fontFamily: 'Gilroy',
            fontSize: '1.1111112vw',
            position: 'absolute',
            top: '1.1805555vw'
        }
    },
    option: (base, state) => {
        return {
            ...base,
            height: '2.5vw',
            width: '31.666vw',
            cursor: 'pointer',
            fontFamily: 'Gilroy',
            fontSize: '1.111112vw'
        };
    },

    menu: (base, state) => ({
        ...base,
        boxShadow: "0 2px 16px 0 rgba(0,0,0,0.15)",
        marginTop: -0.5
        
    }),
    menuList: (base, state) => ({
        ...base,
        padding: 0,
        borderRadius: 8,
        boxShadow: "0 0px 16px 0 rgba(0,0,0,0.15)",
        height: '15vw',
        width: '31.8055vw'
    }),
};


export class signup extends Component {

    state = {
        fullname: null,
        email: null,
        selectedOption: null,
        selectedSector: null,
        subType: null,
        messageStatus: false,
        invalidEmail: false,
        invalidName: false,
        invalid: false,
        empty: false,
        message: '',
        tpName: '',
        tpId: '',
        tpState: '',
        tpDistrict: '',
        tpMobile: null
    };

    componentDidMount() {
        console.log("TOKEN", this.props.token)
        ReactGA.pageview('/nsdc')
    }

    handleChange = selectedOption => {

        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    handleChange2 = selectedSector => {

        this.setState({ selectedSector });
        console.log(`Option: `, selectedSector);
    };

    redirect = () => {
        this.props.history.push({
            pathname: "/check",
            state: true })
    }

    onSubmit = async () => {

        if (this.state.selectedOption.value === "other" || this.state.selectedOption.value === "government") {
                await this.validateNull(this.state.subType, this.state.fullname, this.state.email, this.state.selectedOption)
        } else if (this.state.selectedOption.value === "sector") {
            await this.validateNull4(this.state.selectedSector, this.state.fullname, this.state.email, this.state.selectedOption)
        } else if (this.state.selectedOption.value === "training") {
            await this.validateNull3(this.state.fullname, this.state.tpName, this.state.tpId, this.state.tpState, this.state.tpDistrict, this.state.tpMobile, this.state.email, this.state.selectedOption)
        } else {
            await this.validateNull2(this.state.fullname, this.state.email, this.state.selectedOption)
        }
        console.log(this.state.empty)

        if ((this.state.empty !== true) && (this.state.selectedOption.value !== "training")) {
            await this.validate(this.state.email)
        }

        console.log(this.state.createdAt)
        
        if((this.state.invalidEmail !== true) && (this.state.invalidName !== true) && (this.state.empty !== true) && (this.state.invalid !== true)) {
            
            let data;
            if (this.state.selectedOption.value === "other" || this.state.selectedOption.value === "government") {
                data = {
                    fullname: this.state.fullname,
                    email: this.state.email,
                    type: this.state.selectedOption.label,
                    subType: this.state.subType
                }
            } else if (this.state.selectedOption.value === "sector") {
                let label = this.state.selectedSector.label

                if(label === "Instrumentation Automation Surveillance ...."){ 
                    label = "Instrumentation Automation Surveillance and Communication"
                }
                data = {
                    fullname: this.state.fullname,
                    email: this.state.email,
                    type: this.state.selectedOption.label,
                    subType: label,
                    nsdcSector: this.state.selectedSector.value
                }
            } else if ( this.state.selectedOption.value === "training") {
                data = {
                    tpName: this.state.tpName,
                    tpId: this.state.tpId,
                    tpState: this.state.tpState,
                    tpDistrict: this.state.tpDistrict,
                    tpMobile: this.state.tpMobile,
                    fullname: this.state.fullname,
                    email: this.state.email,
                    type: this.state.selectedOption.label
                }
            } else {
                data = {
                    fullname: this.state.fullname,
                    email: this.state.email,
                    type: this.state.selectedOption.label
                }
            }

            ReactGA.event({
                category: 'Sign Up',
                action: 'New User Created'
              });
            
            axios.post(config.serverUrl + "generatePassword", data)
            .then(response => 
                (response.data.status === "success") ? ( this.redirect() ) : null || 
                (response.data.status === 409) ? this.setState({ messageStatus: true }) : null
            ) 
            .catch(error => {
                console.log("error",error);
            });
        }
    }

    redirect2 = () => {

        ReactGA.event({
            category: 'Sign Up',
            action: 'Redirected to Login Page'
          });
        
        this.props.history.push({
            pathname: "/login",
            state: true })
    }

    handleEvent = e => {
        this.validateEmail(e.target.value)
        this.setState({
            email: e.target.value,
            invalid: false
        })

    }

    validateEmail = name => {
        var regex =/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        if(!regex.test(name)){
            this.setState({
                invalidEmail: true,
                empty: false,
                messageStatus: false
            })
        } else {
            this.setState({
                invalidEmail: false,
                empty: false,
                messageStatus: false
            })
        }
    }

    validate = email => {
        return new Promise((resolve, reject) => {
            console.log("reached")

        var invalidEmails = ["yahoo.com", "gmail.com", "hotmail.com", "example.com", "yahoo.co.id", "yahoo.co.in", "outlook.com", "zoho.com", "mail.com", "yahoomail.com", "aol.com", "hotmail.co.uk", "hotmail.fr", "msn.com", "yahoo.fr", "wanadoo.fr", "orange.fr", "comcast.net", "yahoo.co.uk", "yahoo.com.br", "live.com", "rediffmail.com", "free.fr", "gmx.de", "web.de", "yandex.ru", "ymail.com", "hotmail.it", "	yahoo.com.sg", "ntlworld.com", "freenet.de"];
        var text = "";
        var i;
        for (i = 0; i < invalidEmails.length; i++) {
            text = invalidEmails[i] 
            if(email.includes(text)){
               this.setState({
                    invalid: true,
                    invalidEmail: false,
                    invalidName: false
               })
            }
        }
        resolve()
        })
    }


    validateNull = (subtype, name, email, type) => {
        return new Promise((resolve, reject) => {
        if (!subtype) {
            this.setState({
                empty: true
            })
        } else if(!name) {
            this.setState({
                empty: true
            })
        } else if (!email) {
            this.setState({
                empty: true
            })
        }  else if (!type) {
            this.setState({
                empty: true
            })
        } else {
            this.setState({
                empty: false
            })
        }
        resolve()
    })
    }

    validateNull4 = (subtype, name, email, type) => {
        return new Promise((resolve, reject) => {
        if (!subtype) {
            this.setState({
                empty: true
            })
        } else if(!name) {
            this.setState({
                empty: true
            })
        } else if (!email) {
            this.setState({
                empty: true
            })
        }  else if (!type) {
            this.setState({
                empty: true
            })
        } else {
            this.setState({
                empty: false
            })
        }
        resolve()
    })
    }

    validateNull2 = (name, email, type) => {
        return new Promise((resolve, reject) => {
        if(!name) {
            this.setState({
                empty: true
            })
        } else if (!email) {
            this.setState({
                empty: true
            })
        }  else if (!type) {
            this.setState({
                empty: true
            })
        } else {
            this.setState({
                empty: false
            })
        }
        resolve()
    })
    }

    validateNull3 = (fullname, name, id, state, district, mobile, email, type) => {
        return new Promise((resolve, reject) => {
        if(!name) {
            this.setState({
                empty: true
            })
        } else if (!id) {
            this.setState({
                empty: true
            })
        }  else if (!state) {
            this.setState({
                empty: true
            })
        } else if (!district) {
            this.setState({
                empty: true
            })
        }
        else if (!mobile) {
            this.setState({
                empty: true
            })
        } else if (!email) {
            this.setState({
                empty: true
            })
        } else if (!type) {
            this.setState({
                empty: true
            })
        } else if (!fullname) {
            this.setState({
                empty: true
            })
        } else {
            this.setState({
                empty: false
            })
        }
        resolve()
    })
    }

    handleEvent2 = e => {
        this.validateName(e.target.value)
        this.setState({
            fullname: e.target.value
        })
    }

    handleEvent3 = e => {
        this.setState({
            subType: e.target.value
        })
    }

    validateName = name => {
        var patt = /^[a-zA-Z\s]+$/i
        if(!patt.test(name)){
            this.setState({
                invalidName: true
            })
        } else {
            this.setState({
                invalidName: false
            })
        }
    }

    handleEvent4 = (event, value) => {
        if (value === "name") {
            this.setState({
                tpName: event.target.value
            })
        } else if (value === "id") {
            this.setState({
                tpId: event.target.value
            })
        } else if (value === "state") {
            this.setState({
               tpState: event.target.value
            })
        } else if (value === "district") {
            this.setState({
               tpDistrict: event.target.value
            })
        } else if (value === "mobile") {
            this.setState({
               tpMobile: event.target.value
            })
        } else if (value === "email") {
            this.validateEmail(event.target.value)
            this.setState({
                email: event.target.value,
                invalid: false
            })
        } else if (value === "fullname") {
            this.validateName(event.target.value)
            this.setState({
               fullname: event.target.value
            })
        }
    }

    render() {
        console.log(this.state.fullname)
        const select = (this.state.selectedOption != null) ? this.state.selectedOption.value : "";
        return (
                <div className={styles.mainrectangle2}>
                    <img src={better} alt="betterplace logo" className={styles.logo} />
                    <div className={styles.block}> 
                        <img src={skill} alt="skill" className={styles.skill}></img>
                            <label className={styles.line} />
                        <img src={NSDC} alt="nsdc" className={styles.nsdc}></img>
                            <label className={styles.line2} />
                        <img src={SMIS} alt="smis" className={styles.smis}></img>
                    </div>
                        <img src={funnel} alt="funnel" className={styles.image}></img>
                        <label className={styles.heading3}> State-wise demand 2019-2020 </label>

                <div className={styles.mainrectangle3}>
                    <div className={styles.smallrectangle}>
                        <label className={styles.heading4}> Sign up to empower India with access </label>
                        <label className={styles.label}> ASEEM – An Integrated Skill Management Information System </label>
                    </div>

                    <div className={styles.block2}>
                        <label className={styles.text}> Signup as </label>
                        <div style={{marginTop: '0.277777vw', position: 'absolute', top: '20.138vw', left: '50.694vw' }}>
                            <Select
                                styles={colourStyles}
                                value={this.state.selectedOption}
                                placeholder="select an option"
                                onChange={this.handleChange}
                                options={options}
                            />
                        </div>
                            { select === "training" ? 
                                    <div>
                                    <div>
                                        <label className={styles.text4}> Name of the Organization </label> 
                                        <input value={this.state.tpName} type="text" className={styles.input3} onChange={(event) => this.handleEvent4(event, "name")}/> 
                                    </div>
                                    <div>
                                        <label className={styles.text7}> TP ID </label>
                                        <input value={this.state.tpId} type="text" className={styles.input} onChange={(event) => this.handleEvent4(event, "id")}/> 
                                    </div>
                                    <div>
                                        <label className={styles.text8}> State </label>
                                        <input value={this.state.tpState} type="email" className={styles.input2} onChange={(event) => this.handleEvent4(event, "state")} /> 
                                    </div>
                                    <div>
                                        <label className={styles.text8}> District </label>
                                        <input value={this.state.tpDistrict} type="email" className={styles.input2} onChange={(event) => this.handleEvent4(event, "district")} /> 
                                    </div>
                                    <div>
                                        <label className={styles.text8}> Mobile No </label>
                                        <input value={this.state.tpMobile} type="email" className={styles.input2} onChange={(event) => this.handleEvent4(event, "mobile")} /> 
                                    </div>
                                    <div>
                                        <label className={styles.text7}> Full Name </label>
                                        <input value={this.state.fullname} type="text" className={styles.input} onChange={(event) => this.handleEvent4(event, "fullname")}/> 
                                    </div>
                                    <div>
                                        <label className={styles.text8}> Email Id </label>
                                        <input value={this.state.email} type="email" className={styles.input2} onChange={(event) => this.handleEvent4(event, "email")} /> 
                                    </div>

                                    <button onClick={this.onSubmit} className={styles.rectangle3}>
                                        <label style={{cursor: "pointer"}}  className={styles.text6}> Proceed </label> 
                                        <img src={arrow} alt="arrow" className={styles.arrow}></img>
                                    </button>
                                    <div>
                                        <label className={styles.text10}> Already have an account?</label>
                                        <label onClick={this.redirect2} className={styles.text11}> Login </label>
                                    </div>
                                </div>
                                :
                                <div>
                                { (select === "other" || select === "government" || select === "sector") ? 
                                    <div>
                                        <div>
                                            { select === "other" ? <label className={styles.text4}> Name of the Ministry </label> : null }
                                            { select === "government" ? <label className={styles.text4}> Name of the Agency </label> : null }
                                            { select === "sector" ? <label className={styles.text4}> Name of the SSC </label> : null }   
                                            { select === "sector" ?    
                                            <div>
                                            <Select
                                                styles={colourStyles}
                                                value={this.state.selectedSector}
                                                placeholder="select a sector"
                                                onChange={this.handleChange2}
                                                options={options2}
                                            />
                                        </div>
                                            : 
                                            <input value={this.state.subType} type="text" className={styles.input3} onChange={(event) => this.handleEvent3(event)}/> 
                                            }
                                        </div>
                                        <div>
                                            <label className={styles.text7}> Full name </label>
                                            <input value={this.state.fullname} type="text" className={styles.input} onChange={(event) => this.handleEvent2(event)}/> 
                                        </div>
                                        <div>
                                            <label className={styles.text8}> Email (only official email ids) </label>
                                            <input value={this.state.email} type="email" className={styles.input2} onChange={(event) => this.handleEvent(event)} /> 
                                        </div>

                                        <button onClick={this.onSubmit} className={styles.rectangle3}>
                                            <label style={{cursor: "pointer"}}  className={styles.text6}> Proceed </label> 
                                            <img src={arrow} alt="arrow" className={styles.arrow}></img>
                                        </button>
                                        <div>
                                            <label className={styles.text10}> Already have an account?</label>
                                            <label onClick={this.redirect2} className={styles.text11}> Login </label>
                                        </div>
                                    </div>
                                    :<div>   
                                        <div>
                                            <label className={styles.text2}> Full name </label>
                                            <input value={this.state.fullname} type="text" className={styles.input} onChange={(event) => this.handleEvent2(event)}/> 
                                        </div>
                                        <div>
                                            <label className={styles.text3}> Email (only official email ids) </label>
                                            <input value={this.state.email} type="email" className={styles.input2} onChange={(event) => this.handleEvent(event)} /> 
                                        </div>
                                        <button onClick={this.onSubmit} className={styles.rectangle3}>
                                            <label style={{cursor: "pointer"}}  className={styles.text6}> Proceed </label> 
                                            <img src={arrow} alt="arrow" className={styles.arrow}></img>
                                        </button>
                                        <div>
                                            <label className={styles.text10}> Already have an account?</label>
                                            <label onClick={this.redirect2} className={styles.text11}> Login </label>
                                        </div>
                                    </div>
                                }
                                </div>
                            }


                            { select === "training" ? 
                            <div>
                                { this.state.invalidName ? <div className={styles.block9}> Please enter a valid name </div> : null }
                                { this.state.messageStatus ? <div className={styles.block8}> Email ID already exists!! </div> : null }
                                { this.state.empty ? <div className={styles.block8}> Please enter data in all the fields </div> : null }
                                { this.state.invalidEmail ? <div className={styles.block8}> Please enter a valid email  </div> : null } 
                            </div>
                            :
                            <div>
                            { (select === "other" || select === "government" || select === "sector") ? 
                            <div>
                                { this.state.invalidName ? <div className={styles.block5}> Please enter a valid name </div> : null }
                                { this.state.messageStatus ? <div className={styles.block6}> Email ID already exists!! </div> : null }
                                { this.state.invalid  ? <div className={styles.block6}> Please enter a valid official mail Id </div> : null }
                                { this.state.empty ? <div className={styles.block6}> Please enter data in all the fields </div> : null }
                                { this.state.invalidEmail ? <div className={styles.block6}> Please enter a valid email  </div> : null }
                            </div>
                            :
                            <div>
                                { this.state.invalidName ? <div className={styles.block4}> Please enter a valid name </div> : null }
                                { this.state.messageStatus ? <div className={styles.block3}> Email ID already exists!! </div> : null }
                                { this.state.invalid  ? <div className={styles.block3}> Please enter a valid official mail Id </div> : null }
                                { this.state.empty ? <div className={styles.block3}> Please enter data in all the fields </div> : null }
                                { this.state.invalidEmail ? <div className={styles.block3}> Please enter a valid email  </div> : null } 
                            </div> }
                            </div>
                            }
                        </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        token: state.token
    }
}

export default withRouter((connect(mapStateToProps, null)(signup)))
