import React, { Component } from 'react';
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import Progress from '../components/progress'

import styles from './supply.module.scss';
import skill from '../../assets/icons/skillIndia.svg'
import nsdc from '../../assets/icons/nsdclogo1.svg'
import smis from '../../assets/icons/smis1.svg'
import betterplace from '../../assets/icons/BetterPlaceWhite.svg';
import supply1 from '../../assets/icons/supply1.svg';
import demand from '../../assets/icons/demand.svg';
import fulfillment from '../../assets/icons/fulfill-inactive.svg';
import logout from '../../assets/icons/logout-inactive.svg'
import container from '../../assets/icons/container.svg'
import cx from 'classnames';
import config from '../../config';

class supply extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            selected:'supply',
            loading: true
        }
    }

    onChangeIcon =(value) => {
        this.setState({
            selected:value
        })

        if(value==='demand'){
            ReactGA.event({
                category: 'Supply',
                action: 'Page Changed to Demand'
              });
            this.props.history.push("demand",{ state: true });
        }else if(value==='fulfillment'){
            ReactGA.event({
                category: 'Supply',
                action: 'Page Changed to Fulfillment'
              });
            this.props.history.push("fulfillment",{ state: true });
        } else if (value === 'logout') {
            ReactGA.event({
                category: 'Supply',
                action: 'Logged Out'
              });
            window.location.assign(config.logoutUrl)
        }
    }

    componentDidMount()
    {
        ReactGA.pageview('/nsdc/supply')
    }

    hideSpinner = () => {
        this.setState({
          loading: false
        });
    };

    

    render() {
        
        let sector
        if (this.props.type === "Sector Skill Council") {
            sector = this.props.subType.toUpperCase()
        }
    
        console.log(config.GDS.candidates + `?params=%7B%22ds35.sector_name%22:%22NSDC%22%7D`)

        return (
            <div className="row no-gutters">
                <div className={cx(styles.sidenav,"col-1")}>
                    <img src={betterplace} alt="betterplace logo" style={{width:"100%"}}></img>
                    <div  style={{marginTop:'1.1111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='supply'?'#E5EEF7':'white',padding:'0.5444444vw',marginLeft:'0.4166666vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('supply')}>
                                <img src={supply1} alt="supply" className={this.state.selected==='supply'?styles.activeIcon:null} style={{height: "1.111112vw", width: "1.1111112vw"}} />
                                <label className={styles.headertext} style={{color:this.state.selected==='supply'?'#0059B2':null}}>candidates</label>
                    </span>
                    <div  style={{marginTop:'1.11111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='demand'?'#E5EEF7':'white',padding:'0.69444444vw',marginLeft:'0.4166666vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('demand')}>
                                <img src={demand} alt="demand" className={this.state.selected==='demand'?styles.activeIcon:null} style={{height: "1.111112vw", width: "1.1111112vw"}} />
                                <label className={styles.headertext} style={{color:this.state.selected==='demand'?'#0059B2':null}}>jobs</label>
                    </span>
                    <div  style={{marginTop:'1.11111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='fulfillment'?'#E5EEF7':'white',padding:'0.69444444vw',marginLeft:'0.1388888vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('fulfillment')}>
                                <img src={fulfillment} alt="fulfillment" className={this.state.selected==='fulfillment'?styles.activeIcon:null} style={{height: "1.466666vw", width: "1.46666vw"}}/>
                                <label className={styles.headertext} style={{color:this.state.selected==='fulfillment'?'#0059B2':null, width: "4.1666666vw"}}>fulfillment</label>
                    </span>
                    <div  style={{marginTop:'1.111111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='logout'?'#E5EEF7':'white',padding:'0.69444444vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('logout')} >
                                <img src={logout} alt="placement" className={this.state.selected==='logout'?styles.activeIcon:null} style={{height: "1.466666vw", width: "1.466666vw"}} />
                                <label className={styles.headertext} style={{color:this.state.selected==='logout'?'#0059B2':null, width: "4.1666666vw"}}>logout</label>
                    </span>
                </div>
                <div className="col-11">
                    <div className="col-8 px-0">
                        <div className="d-flex" style={{marginTop:"2.2222223vw"}}>
                            <img src={smis} alt="smis" className={styles.smis}></img>
                            <img src={skill} alt="skill" className={styles.skill}></img>
                            <img src={nsdc} alt="nsdc" className={styles.nsdc}></img>
                            
                            {/* { this.props.type === "Sector Skill Council" ?
                            <div className="d-flex justify-content-end" onClick={() => this.downloadReport()} style={{padding:"0.69444444vw 1.1111112vw 0.625vw",paddingLeft: "1.805555vw", marginLeft: "24.2222vw" ,borderRadius: "20px", backgroundColor: "#E5EEF7", width: "16.66666vw", height: "2.777777vw", cursor: "pointer" }}>
                                <img src={downloadReport} alt="arrow" style={{height: '1.388888vw', width: '1.388888vw'}}></img>
                                <div className={styles.alllocations} >download report</div>
                            </div> : null } */}
                        </div>

                    { this.props.type === "Sector Skill Council" ? 
                        <div className="d-flex justify-content" style={{marginTop:"2.222222vw", marginLeft:"2.777777vw"}}>
                            <img src={container} alt="respondedCandidates"  style={{height: "2.6666666667vw", width: "2.6666666667vw"}}></img>
                            <label className={styles.nsdcProfileData}>{ this.props.nsdcSector} Sector</label>
                        </div>
                    : null }
                    <div className="d-flex justify-content" style={{marginTop:"1.2777vw", marginLeft:"2.99999vw", fontSize:"1.433335vw", fontFamily: "Gilroy-Medium", width: "80vw"}}>
                        <label> Candidates Dashboard provides a view of all potential candidates available in the platform across State, District, Sector, Job Role and Gender. { this.props.type !== "Sector Skill Council" ? "It also shows a timeline view of supply added over a period of time." : null } All candidate profiles on this platform are curated and reachable. </label>
                    </div>
                    <div className="d-flex justify-content" style={{marginTop:"1.2777vw", marginLeft:"2.99999vw", fontSize:"1.133335vw", fontFamily: "Gilroy", width: "80vw"}}>
                        <label> *The dashboard is running on live data and hence it may take few seconds to load on slower connections. </label>
                    </div>
                    { this.props.type !== "Sector Skill Council" ? 
                        <div>
                            <div className={styles.nsdcCandidateResponse}>    
                                { this.state.loading ? 
                                <div className="d-flex justify-content">
                                    <Progress />
                                </div> 
                                : null }
                                <div className="d-flex justify-content">
                                  <iframe id="myFrame" width="1500" height="3400" src={config.GDS.candidates + `:%22NSDC%22%7D`} title="Data Studio Google" allowFullScreen={true} onLoad={this.hideSpinner} frameBorder="0"  scrolling="no" />
                                </div> 
                            </div>
                        </div> 
                        : 
                        <div >
                            <div className={styles.nsdcCandidateResponse}>
                                { this.state.loading ? 
                                        <div className="d-flex justify-content">
                                            <Progress />
                                        </div> 
                                        : null }
                                <div className="d-flex justify-content">
                                <iframe width="1500" height="3400" src={config.GDS.candidates + `:%22${sector}%22%7D`} title="Data Studio Google" allowFullScreen={true}  onLoad={this.hideSpinner} frameBorder="0"  scrolling="no" />
                                </div> 
                            </div>
                        </div>
                        }
                    </div>
                <div>
            </div>
        </div>
    </div>       
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.token, 
        subType: state.subType,
        type: state.type,
        nsdcSector: state.nsdcSector
    }
}

export default (connect(mapStateToProps, null)(supply))