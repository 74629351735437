import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Router, Route } from 'react-router-dom';

import Supply from './NSDC/supply/supply'
import Demand from './NSDC/demand/demand'
import signup from './NSDC/signupPage/signup'
import check from './NSDC/check/check'
import login from './NSDC/login/login'
import Reset from './NSDC/reset/reset'
import Fulfillment from './NSDC/fulfillment/fulfillment'

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

let react_env = process.env.REACT_APP_ENV
const history = createBrowserHistory()

if(react_env === 'production') {
  ReactGA.initialize('UA-173137531-1', {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123,
      siteSpeedSampleRate: 100
    }
  });
}

ReactGA.pageview('/login')
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});


let app = (
  <Router history={ history } >
      <div>
      <Route exact path = '/nsdc' component={signup} />
      <Route path = '/check' component={check} />
      <Route path = '/login' component={login} />
      <Route path = '/reset' component={Reset} />
      <Route path = '/nsdc/supply' component = {Supply} />
      <Route path = '/nsdc/fulfillment' component = {Fulfillment} />
      <Route path = '/nsdc/demand' component = {Demand} />
      </div>
  </Router>
)

class App extends Component {

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 50);
          var allowURL = 0;
          var URL = window.location.pathname;

          switch (URL) {
          case '/login': 
          allowURL = 1;
          break;
          case '/reset':
          allowURL = 1;
          break;
          default:
          allowURL = 0;
          }
          if (allowURL === 0) {
          window.location.href = '/login';
          }
  }

  render () {
    return (
      <div>
         <MetaTags>
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </MetaTags>
          { app } 
      </div>
    )
  }
}

export default App;
