import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import styles from './login.module.scss'
import axios from 'axios';
import ReactGA from 'react-ga'
import config from '../../config';

import funnel from '../../assets/icons/login.svg'
import arrow from '../../assets/icons/side.svg'
import skill from '../../assets/icons/skillIndia.svg'
import NSDC from '../../assets/icons/nsdclogo1.svg'
import SMIS from '../../assets/icons/smis1.svg'
import better from '../../assets/icons/betterlogo.svg'

import { connect } from 'react-redux'
import * as actions from '../../store/actions'

export class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            empty: false,
            forgot: false,
            wrongpassword: false,
            apiState: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount () {
        ReactGA.pageview('/login')
    }

    redirectPath = (type, token, subType, nsdcSector) => {
        console.log("SubType", subType)
        console.log("Token",token)
        if (type === "Training Partners") {

            ReactGA.event({
                category: 'Login ',
                action: 'User Logged In (Training Partner)'
              });

            this.props.onSendType(type, subType, nsdcSector)
            this.props.onSendToken(token)
            this.props.history.push({
                pathname: "/nsdc/demand",
                state: true })
        } else {

            ReactGA.event({
                category: 'Login ',
                action: `User Logged In (${type})`
              });

            this.props.onSendType(type, subType, nsdcSector)
            this.props.onSendToken(token)
            this.props.history.push({
                pathname: "/nsdc/supply",
                state: true })
        }
    }

    redirectPath2 = () => {
        this.props.history.push({
            pathname: "/check",
            state: true })
    }

    onFormSubmit = async () => {
        this.setState({
            apiState: true
        })

        const data = {
            email: this.state.email.trim(),
            password: this.state.password.trim()
        }

        await this.validateNull(this.state.email, this.state.password)

        if (this.state.empty !== true) {
            axios.post(config.serverUrl + "NSDCUserLogin",data)
            .then(response => 
                (response.data.status === "success") ? ( this.redirectPath(response.data.type, response.data.token, response.data.subType, response.data.nsdcSector) ) : (this.setState({ wrongpassword: true })) || 
                (response.data.status === 409) ? this.setState({ messageStatus: true }) : null
            ) 
            .catch(error => {
                console.log("error",error);
                this.setState({
                    wrongpassword: true,
                    apiState: false
                })
            });
        }
    };

    redirect2 = () => {

        ReactGA.event({
            category: 'Login ',
            action: 'Redirected to Sign Up Page'
          });
        this.props.history.push({
            pathname: "/nsdc",
            state: true })
    }

    handleEvent = (event, value) => {
        if (value === "email") {
            this.setState({
                email: event.target.value
            })
        } else if (value === "password") {
            this.setState({
                password: event.target.value
            })
        }
    }

    forgotpassword = async () => {
        const data = {
            email: this.state.email
        }

        await this.validateNull2(this.state.email)

        if (this.state.forgot !== true) {
            axios.post(config.serverUrl + "forgotpassword",data)
            .then(response => {
                this.setState({
                    apiState: false
                });
                (response.data.status === "success") ? ( this.redirectPath2() ) : (this.setState({ wrongpassword: true })) 
                console.log(config.serverUrl)
            }) 
            .catch(error => {
                console.log("error",error);
                this.setState({
                    wrongpassword: true,
                    apiState: false
                })
            });
        }
    }

    validateNull = (email, password) => {
        return new Promise((resolve, reject) => {
        if(!email) {
            this.setState({
                empty: true,
                forgot: false,
                messageStatus: false
            })
        } else if (!password) {
            this.setState({
                empty: true,
                forgot: false,
                messageStatus: false
            })
        }  else {
            this.setState({
                empty: false,
                forgot: false,
                messageStatus: false
            })
        }
        resolve()
        })
    }

    validateNull2 = (email) => {
        return new Promise((resolve, reject) => {
        if(!email) {
            this.setState({
                forgot: true,
                empty: false,
                messageStatus: false
            })
        } else {
            this.setState({
                empty: false,
                forgot: false,
                messageStatus: false
            })
        }
        resolve()
        })
    }

    render() {

        return (
                <div className={styles.mainrectangle2}>
                    <img src={better} alt="betterplace logo" className={styles.logo} />
                    <div className={styles.block}> 
                        <img src={skill} alt="skill" className={styles.skill}></img>
                            <div className={styles.line} />
                        <img src={NSDC} alt="nsdc" className={styles.nsdc}></img>
                            <div className={styles.line2} />
                        <img src={SMIS} alt="smis" className={styles.smis}></img>
                    </div>
                        <img src={funnel} alt="funnel" className={styles.image}></img>
                        <label className={styles.heading3}> Attrition trends 2019-2020 </label>

                <div className={styles.mainrectangle3}>
                    <div className={styles.smallrectangle}>
                        <label className={styles.heading4}> Log in to empower India with access </label>
                        <label className={styles.label}> ASEEM – An Integrated Skill Management Information System </label>
                    </div>

                    <div className={styles.block2}>
                            <label className={styles.text2}> Email </label>
                            <input className={styles.input} value={this.state.email} placeholder="Enter your email" name="name" onChange={(event) => this.handleEvent(event, "email")} ></input>
                            { this.state.messageStatus ? <label className={styles.email}> Email or password you have entered, is not correct. </label> : null}
                            { this.state.empty ? <label className={styles.email}> Please enter data in all the fields </label> : null }
                            { this.state.forgot ? <label className={styles.email}> Please enter the email </label> : null }
                            <label className={styles.text3}> Password </label>
                            <input className={styles.input2} type="password" value={this.state.password} placeholder="Enter your password" name="password" onChange={(event) => this.handleEvent(event, "password")} ></input> 
                            {/* <label onClick={this.forgotpassword} className={styles.forgot}> Forgot password? </label> */}
                        </div>
                        <button onClick={this.onFormSubmit} className={styles.rectangle3}>
                                    <label style={{cursor: "pointer"}}  className={styles.text6}> Login </label> 
                                    <img src={arrow} alt="arrow" className={styles.arrow}></img>
                        </button>
                        <div>
                        <label className={styles.text10}> Don't have an account?</label>
                        <label onClick={this.redirect2} className={styles.text11}> Signup </label>
                        </div>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onSendType: (type, subType, nsdcSector) => dispatch(actions.sendtype(type, subType, nsdcSector)),
        onSendToken: (token) => dispatch(actions.sendtoken(token))
    };
};


export default withRouter(connect(null, mapDispatchToProps)(login))
