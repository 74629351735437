import development from './config/development';
import production from './config/production';
import test from './config/test';

let env = development
if (process.env.REACT_APP_ENV === 'production') {
    env = production
} else if (process.env.REACT_APP_ENV === 'test') {
    env = test
}

const config = env
export default config; 