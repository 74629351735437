import * as actionTypes from './actionTypes';

export const sendtype = (orgtype, subType, nsdcSector) => {
  return (dispatch) => {
          dispatch({
              type: actionTypes.SEND_TYPE,
              orgtype: orgtype,
              subType : subType,
              nsdcSector: nsdcSector
          })
  };
};

export const sendtoken = (token) => {
  return (dispatch) => {
          dispatch({
              type: actionTypes.SEND_TOKEN,
              token: token
          })
  };
};