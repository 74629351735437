import * as actionTypes from './actionTypes';
import { updateObject } from './utility';

const initialState = {
    sample: [],
    type: '',
    token: '',
    subType: '',
    nsdcSector: ''
}

const initState = () => {
    return initialState;
}

const sendtype = (state, action) => {
    return updateObject(state, {
        type: action.orgtype,
        subType: action.subType,
        nsdcSector: action.nsdcSector
    })
}

const sendToken = (state, action) => {
    return updateObject(state, {
        token: action.token
    })
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE: return initState();

        case actionTypes.SEND_TYPE: return sendtype(state, action);
        case actionTypes.SEND_TOKEN: return sendToken(state, action);

    default: return state;
    }
}

export default reducer