import React, { Component } from 'react';
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

import Progress from '../components/progress'

import styles from './fulfillment.module.scss';
import skill from '../../assets/icons/skillIndia.svg'
import nsdc from '../../assets/icons/nsdclogo1.svg'
import smis from '../../assets/icons/smis1.svg'
import betterplace from '../../assets/icons/BetterPlaceWhite.svg';
import supply1 from '../../assets/icons/supply1.svg';
import demand from '../../assets/icons/demand.svg';
import fulfillment from '../../assets/icons/fulfill-active.svg';
import logout from '../../assets/icons/logout-inactive.svg'
import cx from 'classnames';
import config from '../../config';
import container from '../../assets/icons/container.svg'

class demandClass extends Component {

    state = {
        selected:'fulfillment',
        loading: true,
        selectedTab: "job"
    }

    onChangeIcon =(value) => {
        this.setState({
            selected:value
        })
        if(value==='supply'){
            ReactGA.event({
                category: 'Demand',
                action: 'Page Changed to Supply'
            });
            this.props.history.push("supply",{state: true});
        }else if(value==='demand'){
            ReactGA.event({
                category: 'Demand',
                action: 'Page Changed to Demand'
            });
            this.props.history.push("demand",{state: true});
        }else if(value==='fulfillment'){
            ReactGA.event({
                category: 'Fulfillment',
                action: 'Page Changed to Fulfillment'
            });
            this.props.history.push("fulfillment",{state: true});
        } else if (value === 'logout') {
            ReactGA.event({
                category: 'Demand',
                action: 'Logged Out'
            });
            window.location.assign(config.logoutUrl)
        }
    }

    componentDidMount()
    {
        ReactGA.pageview('/nsdc/demand')
    }

    hideSpinner = () => {
        this.setState({
          loading: false
        });
    };

    handleTab = (tabName) => {
        this.setState({
            selectedTab: tabName,
            loading: true
        })
    }

    render() {

        let sector
        if (this.props.type === "Sector Skill Council") {
            sector = this.props.subType.toUpperCase()
        }

        let notAvailable = "Training Partners"

        return (
            <div className="row no-gutters">
                <div className={cx(styles.sidenav,"col-1")}>
                    <img src={betterplace} alt="betterplace logo" style={{width:"100%"}}></img>
                    <div  style={{marginTop:'1.11111112vw'}}/>
                    { (this.props.type !== notAvailable) ? <span  style={{backgroundColor:this.state.selected==='supply'?'#E5EEF7':'white',padding:'0.5444444vw',marginLeft:'0.4166666vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('supply')}>
                                <img src={supply1} alt="supply" className={this.state.selected==='supply'?styles.activeIcon:null} style={{height: "1.111112vw", width: "1.1111112vw"}}/>
                                <label className={styles.headertext} style={{color:this.state.selected==='supply'?'#0059B2':null}}>candidates</label>
                    </span> :null }
                    <div  style={{marginTop:'1.11111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='demand'?'#E5EEF7':'white',padding:'0.69444444vw',marginLeft:'0.4166666vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('demand')}>
                                <img src={demand} alt="demand" className={this.state.selected==='demand'?styles.activeIcon:null} style={{height: "1.111112vw", width: "1.1111112vw"}}/>
                                <label className={styles.headertext} style={{color:this.state.selected==='demand'?'#0059B2':null}}>jobs</label>
                    </span>
                    <div  style={{marginTop:'1.11111112vw'}}/>
                    { (this.props.type!== notAvailable) ? <span  style={{backgroundColor:this.state.selected==='fulfillment'?'#E5EEF7':'white',padding:'0.69444444vw',marginLeft:'0.1388888vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('fulfillment')}>
                                <img src={fulfillment} alt="fulfillment" className={this.state.selected==='fulfillment'?styles.activeIcon:null} style={{height: "1.4666vw", width: "1.46666vw"}}/>
                                <label className={styles.headertext} style={{color:this.state.selected==='fulfillment'?'#0059B2':null, width: "4.1666666vw"}}>fulfillment</label>
                    </span> : null }
                    <div  style={{marginTop:'1.111111112vw'}}/>
                    <span  style={{backgroundColor:this.state.selected==='logout'?'#E5EEF7':'white',padding:'0.69444444vw',borderRadius:'0.27777778vw'}} onClick={()=>this.onChangeIcon('logout')} >
                                <img src={logout} alt="placement" className={this.state.selected==='logout'?styles.activeIcon:null} style={{height: "1.466666vw", width: "1.466666vw"}} />
                                <label className={styles.headertext} style={{color:this.state.selected==='logout'?'#0059B2':null, width: "4.1666666vw"}}>logout</label>
                    </span>
                </div>
                <div className="col-11">
                    <div className="col-8 px-0">
                        <div className="d-flex" style={{marginTop:"2.22222223vw"}}>
                        <img src={smis} alt="smis" className={styles.smis}></img>
                        <img src={skill} alt="skill" className={styles.skill}></img>
                        <img src={nsdc} alt="nsdc" className={styles.nsdc}></img>
                       
                        {/* { (this.props.type!== notAvailable) ? <div className="d-flex justify-content-end" onClick={() => this.downloadReport()} style={{padding:"0.69444444vw 1.1111112vw 0.625vw",paddingLeft: "1.805555vw", marginLeft: "24.2222vw" ,borderRadius: "20px", backgroundColor: "#E5EEF7", width: "16.66666vw", height: "2.777777vw", cursor: "pointer" }}>
                                <img src={downloadReport} alt="arrow" style={{height: '1.388888vw', width: '1.388888vw'}} ></img>
                                <div className={styles.alllocations} >download report</div>
                            </div>
                        :null } */}

                    </div>
                </div>      

                { this.props.type === "Sector Skill Council" ? 
                     <div className="d-flex justify-content" style={{marginTop:"2.22223vw", marginLeft:"2.777777vw"}}>
                        <img src={container} alt="respondedCandidates"  style={{height: "2.6666667vw", width: "2.66666676vw"}}></img>
                        <label className={styles.nsdcProfileData}>{ this.props.nsdcSector} Sector</label>
                    </div>
                    : null }
                    <div>
                        <div className="d-flex justify-content" style={{marginTop:"0.7777vw", marginLeft:"2.99999vw", fontSize:"1.333335vw", width: "80vw"}}>
                            <div style={{ paddingLeft: '1.667vw', paddingRight: '1.667vw', paddingTop: '0.555vw', paddingBottom: '0.555vw', borderStyle: "solid", borderWidth: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: "#DDDDDD", cursor: 'pointer' }} onClick={() => this.handleTab("job")}>
                                <label style={{ fontFamily: (this.state.selectedTab ==="job" ? "Gilroy-Bold" : "Gilroy" ), marginBottom: 0, cursor: 'pointer'}}> Candidate Outreach </label>
                            </div>
                            <div style={{ paddingLeft: '1.667vw', paddingRight: '1.667vw', paddingTop: '0.555vw', paddingBottom: '0.555vw', borderStyle: "solid", borderWidth: 1, borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: "#DDDDDD", cursor: 'pointer' }} onClick={() => this.handleTab("funnel")}>
                                <label style={{ fontFamily: (this.state.selectedTab ==="funnel" ? "Gilroy-Bold" : "Gilroy" ), marginBottom: 0, cursor: 'pointer'}}> Fulfillment Funnel </label>
                            </div>
                        </div>
                        { this.state.selectedTab === "job" ? 
                            <div className="d-flex justify-content" style={{marginTop:"1.7777vw", marginLeft:"2.99999vw", fontSize:"1.433335vw", fontFamily: "Gilroy-Medium", width: "80vw"}}>
                                <label> This dashboard provides summary of all candidate outreach to potential job seekers across all states, sectors and job roles for all the live hiring demands we have. </label>
                            </div>
                        : null }
                        { this.state.selectedTab === "funnel" ? 
                            <div className="d-flex justify-content" style={{marginTop:"1.7777vw", marginLeft:"2.99999vw", fontSize:"1.433335vw", fontFamily: "Gilroy-Medium", width: "80vw"}}>
                                <label> Here is the summary of the job opportunities extended and the number of job seekers who have shown interest towards a job, applied for a job, got selected and joined a job. </label>
                            </div>
                        : null }
                         <div className="d-flex justify-content" style={{marginTop:"1.7777vw", marginLeft:"2.99999vw", fontSize:"1.133335vw", fontFamily: "Gilroy", width: "80vw"}}>
                            <label> *The dashboard is running on live data and hence it may take few seconds to load on slower connections. </label>
                        </div>
                        { this.props.type !== "Sector Skill Council" ?
                        <div>
                            { this.state.selectedTab === "job" ? 
                                <div className={styles.nsdcCandidateResponse}>
                                        { this.state.loading ? 
                                            <Progress />
                                        : null }
                                        <div className="d-flex justify-content">
                                            <iframe width="1200" height="2200" src={config.GDS.candidateOutreach + `:%22NSDC%22%7D`} title="Data Studio Google" allowFullScreen={true}  onLoad={this.hideSpinner} frameBorder="0"  scrolling="no" />
                                        </div> 
                                </div>
                            : null }
                            { this.state.selectedTab === "funnel" ? 
                                <div className={styles.nsdcCandidateResponse}>
                                        { this.state.loading ? 
                                            <Progress />
                                        : null }
                                        <div className="d-flex justify-content">
                                            <iframe width="1200" height="2800" src={config.GDS.fulfillmentFunnel + `:%22NSDC%22%7D`} title="Data Studio Google" allowFullScreen={true}  onLoad={this.hideSpinner} frameBorder="0"  scrolling="no" />
                                        </div> 
                                </div>
                            : null }
                        </div>
                        :
                        <div>
                            { this.state.selectedTab === "job" ? 
                                <div className={styles.nsdcCandidateResponse}>
                                    { this.state.loading ?         
                                        <Progress />
                                    : null }
                                    <div className="d-flex justify-content">
                                    <iframe width="1200" height="2400" src={config.GDS.candidateOutreach + `:%22${sector}%22%7D`} onLoad={this.hideSpinner}  title="Data Studio Google" allowFullScreen={true} frameBorder="0"  scrolling="no" />

                                    </div> 
                                </div>
                            : null }
                            { this.state.selectedTab === "funnel" ? 
                                <div className={styles.nsdcCandidateResponse}>
                                        { this.state.loading ? 
                                            <Progress />
                                        : null }
                                        <div className="d-flex justify-content">
                                            <iframe width="1200" height="2800" src={config.GDS.fulfillmentFunnel + `:%22${sector}%22%7D`} title="Data Studio Google" allowFullScreen={true}  onLoad={this.hideSpinner} frameBorder="0"  scrolling="no" />
                                        </div> 
                                </div>
                            : null }
                        </div>
                        }
                    </div> 
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        type: state.type,
        token: state.token,
        subType: state.subType,
        nsdcSector: state.nsdcSector
    }
}

export default (connect(mapStateToProps, null)(demandClass))