import React, { Component } from 'react'
import styles from './check.module.scss'

import message from './../../assets/icons/message.svg'
import arrow from './../../assets/icons/side.svg'

export class signup extends Component {

     redirect = () => {
        this.props.history.push({
            pathname: "/login",
            state: true })
    }

    render() {
        return (
            <div className={styles.rectangle}>
                <img src={message} alt="message" className={styles.image} />
                <label className={styles.label}> Congratulations! </label>
                <label className={styles.label2}> Thank you for registering with SMIS portal. </label>
                <label className={styles.label3}> Your request has been sent to SMIS Admin for further processing. </label>
                <label className={styles.label4}> You will be notified once your account has been approved. </label>
                <button onClick={this.redirect} className={styles.small}>
                    <label className={styles.text}> Login </label>
                    <img src={arrow} alt="arrow" className={styles.second} />
                </button>
            </div>
        )
    }
}

export default signup
