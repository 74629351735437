import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import styles from './reset.module.scss'
import axios from 'axios';
import config from '../../config';

import skill from '../../assets/icons/skillIndia.svg'
import NSDC from '../../assets/icons/nsdclogo1.svg'
import SMIS from '../../assets/icons/smis1.svg'
import better from '../../assets/icons/betterlogo.svg'

export class reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm: '',
            token: '',
            same : false,
            empty: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        this.setState({
            token: token
        })
    }

    redirectPath = () => {
        window.location.assign("http://localhost:3001/login")
    }

    onFormSubmit = async () => {
       
        await this.validateNull(this.state.password, this.state.confirm)
        if (this.state.empty !== true) {
            await this.validatePassword(this.state.password, this.state.confirm)
        }

        if ((this.state.empty !== true) && (this.state.same !== true)) {

            const data =  {
                token: this.state.token,
                password: this.state.password
            }

            axios.post(config.serverUrl + "setpassword",data)
            .then(response => 
                (response.data.status === "success") ?  this.redirectPath()  : null
            ) 
            .catch(error => {
                console.log("error",error);
            });
        }

    };

    redirect2 = () => {
        this.props.history.push({
            pathname: "/nsdc",
            state: true })
    }

    handleEvent = (event, value) => {
        if (value === "password") {
            this.setState({
                password: event.target.value
            })
        } else if (value === "confirm") {
            this.setState({
                confirm: event.target.value
            })
        }
    }

    validateNull = (password, confirm) => {
        console.log("Validate Null Reached")
        return new Promise((resolve, reject) => {
        if(!password) {
            this.setState({
                empty: true,
                same: false
            })
        } else if (!confirm) {
            this.setState({
                empty: true,
                same: false
            })
        }  else {
            this.setState({
                empty: false,
                same: false
            })
        }
        resolve()
        })
    }

    validatePassword = (password, confirm) => {
        return new Promise((resolve, reject) => {
        if (password !== confirm) {
            this.setState({
                same: true,
                empty: false
            })
        } else {
            this.setState({
                same: false,
                empty: false
            })
        }
        resolve()
        })
    }

    render() {

        return (
                <div className={styles.mainrectangle2}>
                    <img src={better} alt="betterplace logo" className={styles.logo} />
                    <div className={styles.block}> 
                        <img src={skill} alt="skill" className={styles.skill}></img>
                            <div className={styles.line} />
                        <img src={NSDC} alt="nsdc" className={styles.nsdc}></img>
                            <div className={styles.line2} />
                        <img src={SMIS} alt="smis" className={styles.smis}></img>
                    </div>

                <div className={styles.mainrectangle3}>
                    <div className={styles.smallrectangle}>
                        <label className={styles.heading4}> Set New Password </label>
                        <label className={styles.label}> Skill Management Information System </label>
                    </div>

                    <div className={styles.block2}>
                            <label className={styles.text2}> New password </label>
                            <input className={styles.input} type="password" value={this.state.password} placeholder="Enter new password" name="name" onChange={(event) => this.handleEvent(event, "password")} ></input>
                            <label className={styles.text3}> Confirm password </label>
                            <input className={styles.input2} type="password" value={this.state.confirm} placeholder="Confirm password" name="password" onChange={(event) => this.handleEvent(event, "confirm")} ></input> 
                            { this.state.empty ? <label className={styles.email}>  Please enter data in all the fields </label> : null }
                            { this.state.same ? <label className={styles.email}>  The passwords do not match </label> : null }
                    </div>
                            <button onClick={this.onFormSubmit} className={styles.rectangle3}>
                                <label style={{cursor: "pointer"}}  className={styles.text6}> Submit </label> 
                            </button>
                </div>
            </div>
        )
    }
}


export default withRouter(reset)
